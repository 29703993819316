// import React from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import "./jobRole.css"; // Create this CSS file to add custom styles
// import { useLocation, useNavigate } from "react-router-dom";
// import { DURATION, WHATWEOFFER } from "../../Config/JDconfig";

// const JobRolePage = () => {
//   const location = useLocation();
//   const {
//     role,
//     JobOverview,
//     RolesAndResponsibilities ,
//     SkillsAndQualifications ,
//     EmploymentType,
//   } = location.state || {};

//   const handleLoginClick = () => {

//     localStorage.setItem("jobTitle", role || ""); // Dynamically store the title
//     window.location.href =
//     //  "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://prathik.d2m2wtcivnya67.amplifyapp.com/CareerForm";
//      "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/CareerForm";
//   };

//   return (
//     <Container style={{ marginTop: "120px" }}>
//       <div
//         className="section-title"
//         data-aos="fade-up"
//         style={{
//           textAlign: "center",
//           padding: "10px",
//           borderRadius: "20px",
//         }}
//       >
//         <h1
//           style={{
//             fontFamily: "impact",
//             margin: "0",
//           }}
//         >
//           {role || "Job Role"}
//         </h1>
//       </div>

//       <Row className="my-4">
//         <Col>
//           <h5>Employment Type:</h5>
//           <p>{EmploymentType || "No description available."}</p>
//         </Col>
//       </Row>
//       <Row className="my-4">
//         <Col>
//           <h5>Job Overview:</h5>
//           <p>{JobOverview || "No description available."}</p>
//         </Col>
//       </Row>
//       <Row className="my-4">
//         <Col>
//           <h5>Roles and Responsibilities:</h5>
//           <ul>
//             {Array.isArray(RolesAndResponsibilities) &&
//             RolesAndResponsibilities.length > 0 ? (
//               RolesAndResponsibilities.map((responsibility, index) => (
//                 <li key={index}>{responsibility}</li>
//               ))
//             ) : (
//               <li>No responsibilities available.</li>
//             )}
//           </ul>
//         </Col>
//       </Row>
//       <Row className="my-4">
//         <Col>
//           <h5>Skills And Qualifications:</h5>
//           <ul>
//             {Array.isArray(SkillsAndQualifications) &&
//             SkillsAndQualifications.length > 0 ? (
//               SkillsAndQualifications.map((SkillsAndQualifications, index) => (
//                 <li key={index}>{SkillsAndQualifications}</li>
//               ))
//             ) : (
//               <li>No responsibilities available.</li>
//             )}
//           </ul>
//         </Col>
//       </Row>

//       <Row className="my-4">
//         <Col>
//           <h5>What we offer:</h5>
//           <ul>
//             {Array.isArray(WHATWEOFFER) &&
//             WHATWEOFFER.length > 0 ? (
//               WHATWEOFFER.map((WHATWEOFFER, index) => (
//                 <li key={index}>{WHATWEOFFER}</li>
//               ))
//             ) : (
//               <li>No responsibilities available.</li>
//             )}
//           </ul>
//           {/* <p>{WHATWEOFFER || "No description available."}</p> */}
//         </Col>
//       </Row>

//       {/* Conditionally render the "Duration and Compensation" section */}
//       {role !== "Cloud Engineer" || "Program/Product Manager" && (
//         <Row className="my-4">
//           <Col>
//             <h5>Duration and Compensation:</h5>
//             <p>{DURATION || "No description available."}</p>
//           </Col>
//         </Row>
//       )}

//       <Button
//         variant="dark"
//         className="apply-now-button"
//         onClick={handleLoginClick}
//         style={{ marginBottom: "40px" }}
//       >
//         Apply Now <span className="arrow">→</span>
//       </Button>
//     </Container>
//   );
// };

// export default JobRolePage;

import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./jobRole.css"; // Add custom styles here
import { useLocation, useNavigate } from "react-router-dom";
import { DURATION, WHATWEOFFER } from "../../Config/JDconfig";

const JobRolePage = () => {
  const location = useLocation();
  const {
    role = "Job Role", // Fallback values if data is missing
    JobOverview = "No job overview available.",
    RolesAndResponsibilities = [],
    SkillsAndQualifications = [],
    EmploymentType = "Not Specified",
    WhatWeOffer,
  } = location.state || {}; // Retrieve state from previous page or default to empty object

  const handleLoginClick = () => {
    localStorage.setItem("jobTitle", role); // Store the role in localStorage
    window.location.href =
       "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://www.dhruthzucitech.solutions/CareerForm";
       //"https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/CareerForm";
  };

  return (
    <Container style={{ marginTop: "120px" }}>
      <div
        className="section-title"
        data-aos="fade-up"
        style={{
          textAlign: "center",
          padding: "10px",
          borderRadius: "20px",
        }}
      >
        <h1
          style={{
            fontFamily: "impact",
            margin: "0",
          }}
        >
          {role}
        </h1>
      </div>

      <Row className="my-4">
        <Col>
          <h5>Employment Type:</h5>
          <p>{EmploymentType}</p>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <h5>Job Overview:</h5>
          <p>{JobOverview}</p>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <h5>Roles and Responsibilities:</h5>
          <ul>
            {RolesAndResponsibilities &&
            typeof RolesAndResponsibilities === "string" ? (
              RolesAndResponsibilities.split("\n").map(
                (responsibility, index) => <li key={index}>{responsibility}</li>
              )
            ) : (
              <li>No responsibilities available.</li>
            )}
          </ul>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <h5>Skills and Qualifications:</h5>
          <ul>
            {SkillsAndQualifications &&
            typeof SkillsAndQualifications === "string" ? (
              SkillsAndQualifications.split("\n").map((skill, index) => (
                <li key={index}>{skill}</li>
              ))
            ) : (
              <li>No skills or qualifications available.</li>
            )}
          </ul>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <h5>What We Offer:</h5>
          <ul>
            {WhatWeOffer && typeof WhatWeOffer === "string" ? (
              WhatWeOffer.split("\n").map((offer, index) => (
                <li key={index}>{offer}</li>
              ))
            ) : (
              <li>No offers available.</li>
            )}
          </ul>
        </Col>
      </Row>

      {/* Conditionally Render Duration and Compensation */}
      {/* {(role !== "Cloud Engineer" && role !== "Program/Product Manager") && (
        <Row className="my-4">
          <Col>
            <h5>Duration and Compensation:</h5>
            <p>{DURATION || "No description available."}</p>
          </Col>
        </Row>
      )} */}

      <Button
        variant="dark"
        className="apply-now-button"
        onClick={handleLoginClick}
        style={{ marginBottom: "40px" }}
      >
        Apply Now <span className="arrow">→</span>
      </Button>
    </Container>
  );
};

export default JobRolePage;